.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 399;
  min-height: 100%;
  background: var(--colour1);
}

.alert {
  position: fixed;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0 !important;
  z-index: 400;
  width: 100%;
  height: 100%;
}


.alertContent {
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 20px;
  width: 300px;
  max-width: calc(100% - 80px);
  overflow: hidden;
  /*https://html-css-js.com/css/generator/box-shadow/*/
  -webkit-box-shadow: 5px 5px 27px 5px rgba(0, 0, 0, 0.31);
  box-shadow: 5px 5px 27px 5px rgba(0, 0, 0, 0.31);
  padding: 30px;
}

.alertHeader {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "anton";
  text-transform: uppercase;
  font-size: 24px;
  font-weight: normal;
  padding: 10px 20px 10px 20px;
  color: var(--black);
}


.alertText {
  display: flex;
  font-family: 'roboto';
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: normal;
  padding: 10px 20px 20px 20px;
  color: var(--black);
  line-height: 18px;
}


.alertFooter {
  max-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5px;
}

.alertFooterColumn{
  flex-direction: column;
  row-gap: 5px;
}