.primaryText {
  font-size: 16px;
  color: var(--white);
  font-weight: 900;
  font-family: 'roboto-bold';
}
.borderlessButtonRedText{
  font-size: 16px;
  color: var(--red);
  font-family: 'roboto'
}
.inverseText {
  font-size: 16px;
  color: var(--colour1);
  font-family: 'roboto';
}
.defaultText {
  font-size: 16px;
  color: var(--white);
  font-family: 'roboto';
  font-weight: normal;
}
.defaultButtonText {
  font-size: 24px;
  color: var(--white);
  font-family: 'anton';
  text-transform: uppercase;
  font-weight: normal;
}
.clearButton {
  background: none;
  border:none;
  padding:0;
  margin:0;
  width:100%;
  cursor: pointer;
}
.button{
  border: none;
  padding:10px;
  border-radius:5px;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.default{
  padding:10px;
}
.primary{
  background-color: var(--colour1);
  color: var(--white);
}
.grey{
  background-color: var(--mediumGray);
}
.inverse{
  background-color: var(--offwhite);
}
.login{
  background-color: var(--black);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  font-family: 'anton';
  text-transform: uppercase;
  min-width: 300px;
  margin:10px 0;
}
.clear{
  background: none;
  margin:0;
  padding:0;
}
.flex {
  background: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
  min-height: auto;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  width: 100%;
}
