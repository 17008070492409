:root {
  --colour1: #D25B13;
  --colour2: #1D4A83;
  --colour3: #85B3BE;
  --lightBlue:#E2EAEC;
  --cream: #FEE8AC;
  --green: #566842;
  --lightGreen: #82cebc;
  --black: #1e1f1e;
  --offwhite: #FDFBEE;
  --white: #FFFFFF;
  --mediumGray: #6e6869;
  --lightGray: #cccccc;
  --red: #BB2F28;
  --disabled: #c4c4c4;
}

@font-face {
  font-family: 'roboto';
  src: local('roboto'),
    url('./assets/fonts/RobotoMono-VariableFont_wght.ttf') format('truetype'),
}

@font-face {
  font-family: 'anton';
  src: local('anton'), url('./assets/fonts/Anton-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'open-sans';
  src: local('open-sans'), url('./assets/fonts/OpenSans-Regular.ttf') format('truetype')
}

html {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}
body.overflow {
  overflow: auto;
}

#root {
  height: 100%;
  font-family: "roboto", Helvetica, Arial, sans-serif;
  color: #1e1f1e;
  
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  height: 100%; 
  row-gap: 10px;
  
}


.bg1{
  background-color: var(--colour1);
  border:1px solid var(--colour1)
}
.centeredContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  row-gap: 10px;
 
}
.loginForm {
  padding:0 50px;
}
.loginButton {
  max-width: 420px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
  ;
}
.launchLogoHolder {
  display: flex;
  justify-content: center;
}

img.launchLogo {
  width: 200px;
  height: auto;
}
input,
select, 
textarea {
  border-radius: 7px;
  border: solid 1px var(--colour1);
  padding: 19px 20px;
  width: 100%;
  box-sizing: border-box;
  color: var(--black);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.passwordInputDiv {
  position: relative;
}

.passwordInputDiv input {
  padding-right: 46px;
}
.passwordVisibilityIcon {
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
}
.reportQuestionContainer {
  background-color: var(--white);
  padding:30px;
  margin-top:30px;
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow:auto;
  flex:1;
  width: 60%;
  max-width: 800px;
}
@media (max-width: 400px) {
  .reportQuestionContainer {
    width: 80%;
    border-right: 3px solid red;
    border-color: var(--colour1);
  }

}