.centeredContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex:1;
    row-gap: 10;
   
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    height: 100%; 
    row-gap: 10;
  }
  
  .settings {
    display:flex;
    align-items:flex-end
  }
  .settingsReport {
    display: flex;
    padding: 0 20;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .loadingContainer {
    flex: 1;
    justify-content: center;
    align-items: center;
    margin:0 10;
  }

  .dashboardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    height: 100%; 
    row-gap: 10;
    background-color: var(--cream);
    padding: 20px;
  }
  .reportContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    height: 100%; 
    background-color: var(--lightBlue);
  }

  .introductionContainer {
    overflow-y: auto;
    overflow-x: hidden;
    flex:1;
    max-width:80%; 
    padding-right: 30px;
  }

  .reportContentContainer {
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    align-items: center;
    width:100%;
    max-height: 100%;
  }
  
  .titleContainer{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
  }
  .rowContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10;
  }

  .confirmPersonalContainer{
    background-color: var(--white);
    display: flex;
    padding:30px;
    border-radius:30px;
    flex-direction: column;
    justify-content: center;
    width:600px; 
    max-width: 90%;
    max-height: calc(100vh - 50px);
    text-align: center;
  }

  .trimImage {
    border:'1px solid red';
    height:50px;
    background-image: url(../../assets/images/trim.png);
    background-repeat: repeat-x;
    background-position: 0,0;
    background-size: 150px 51px;
    width:100%;
    position: absolute;
    bottom:-20px; left:0;
    background-color: var(-colour2);
  }

    /* width */
    .scroll::-webkit-scrollbar {
      width: 3px;
      }
  
      /* Track */
      .scroll:-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px grey; 
      border-radius: 5px;
      }
  
      /* Handle */
      .scroll::-webkit-scrollbar-thumb {
      background: var(--colour1);
      border-radius: 5px;
      }
  
      /* Handle on hover */
      .scroll::-webkit-scrollbar-thumb:hover {
      background: var(--colour1);
      }

      