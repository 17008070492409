.HB_bg {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffffffbb;
  height: 100%;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: right;
}

.HB_left {
  flex-grow: 1;
}

.HB_container {
  height: 100%;
  border-radius: 30px 0 0 30px;
  max-width: 80%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/images/bg2.png);
  background-repeat: no-repeat;
  background-position: 0,0;
  background-size: contain;
}
.HB_bg1 {
  background-color: var(--colour1);
  background-image: url(../../assets/images/bg2.png);
}

.HB_bg2 {
  background-color: var(--colour2);
  background-image: url(../../assets/images/bg4.png);
}

.HB_bgColour1 {
  background-color: var(--colour1);
}

.HB_bgColour2 {
  background-color: var(--colour2);
}

.HB_iconContainer {
  display: flex;
  justify-content: right;
  margin: 20px 20px 40px 0;
}

.HB_icon {
  width: 30px;
}

.HB_scrollContainer {
  overflow-y: auto;
  padding-bottom: 50px;
}

.HB_buttonText{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}


.HB_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 150px 20px 30px;
  flex: 1;
}


.HB_buttonText {
  color: var(--white);
  margin: 0 20px;
  flex: 1;
  font-weight: normal;
  font-family: 'anton', Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-align: left;
}

