.topMenu {
  position: absolute;
  right:0;
  display: flex;
  top:10px;
  right:10px;
  justify-content: center;
}

.topMenuTitle {
  flex: 1;
  text-align: center;
  margin-right: 30px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 10px;
}

.nameInput {
  color: var(--black300);
  border: none;
  background: none;
  flex: 1;
  text-align: center;
  margin-left: 60px;
  font-family: 'Intel', Arial, Helvetica, sans-serif;
  text-overflow: ellipsis;
}

.nameInput::placeholder {
  color: var(--black300);
}

.nameInput,
.nameInput::placeholder {
  font-size: 24px;
  font-weight: 700;
  font-family: "Inter", Arial, sans-serif;
}


.festivalName {
  background-color: #FFF;
  padding: 9px 15px;
  margin-top: 22px;
  border-radius: 16px;
  box-shadow: 5px 7px 11px -4px rgba(0, 0, 0, 0.15);
  color: var(--black500);
  font-family: "Inter", Helvetica, Arial, sans-serif;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 700;
}


.icon {
  margin-right: 20px;
  margin-top: 12px;
  width: 25px;
  /*filter to invert from white icon to black icon*/
  filter: invert(100%) sepia(7%) saturate(157%) hue-rotate(118deg) brightness(116%) contrast(100%);
}


@media (max-width: 768px) {
  .icon {
    margin-right: 0px;
  }

  .nameInput {
    margin-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}