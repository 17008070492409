.heading1 {
  color: var(--colour1);
  font-family: 'anton'!important;
  text-transform: uppercase;
  font-weight: normal;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  font-size: 32px;
  margin:0;
}

.heading2 {
  text-align: left;
  width: 100%;
  margin: 10 0;
  color: var(--colour2);
  font-family: 'anton'!important;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
}
.centred {
  text-align: center;
  justify-content: center;
}

.inverse {
  color: var(--cream)
}