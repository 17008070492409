.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  min-height: 100%;
  background: var(--offwhite);
}

.modal {
  position: fixed;
  display: flex;
  top: 0;
  left: 0 !important;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.veryTop {
  z-index: 300;
  background: rgb(202, 252, 214);
  background: linear-gradient(0deg, var(--green-light-gradient) 0%, var(--white) 40%, var(--white) 60%, var(--green-light-gradient) 100%);
}

.modalContent {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  width: 100%;
  background-color: var(--colour1);
  display: flex;
  justify-content: center;
}

.headerContainer {
  display: flex;
  color: var(--white);
  padding: 22px 30px;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  width: 100%;
  box-sizing: border-box;
}

.headerCenter {
  flex: 1;
  text-align: center;
  font-family: 'anton';
  font-weight: normal;
  text-transform: uppercase;
  font-size: 32px;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  padding: 10px;
  font-family: "roboto";
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
}



.contentText {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  min-width: 300px;
  text-align: center;
}



.contentTextTop {
  justify-content: flex-start;
  padding-top: 20px;
}

.contentText h1 {
  font-family: 'roboto';
  text-align: left;
}

.contentText h2 {
  font-family: 'roboto', Arial, Helvetica, sans-serif;
}

.contentText h3 {
  color: var(--black);
  text-align: center;
}

.contentTextTop,
.contentTextTop h2,
.contentTextTop h3 {
  text-align: left;
}

.contentText p {
  font-weight: normal;
}

.contentText img{
  max-width: 100%;
}

.contentText ul {
  text-align: left;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerContent {
  max-width:300px;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 15px 20px;
}


.cursorPointer {
  cursor: pointer;
}

a,
a span {
  color: var(--colour1) !important;
}